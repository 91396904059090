import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];
  static values = { activeClasses: Array, inactiveClasses: Array };

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget);
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      if (index === this.index) {
        tab.classList.add(...this.activeClassesValue);
        tab.classList.remove(...this.inactiveClassesValue);
      } else {
        tab.classList.remove(...this.activeClassesValue);
        tab.classList.add(...this.inactiveClassesValue);
      }

      this.panelTargets.forEach((panel, index) => {
        panel.classList.toggle("hidden", index != this.index);
      });
    });
  }

  get index() {
    return parseInt(this.data.get("index"));
  }

  set index(value) {
    this.data.set("index", value);
    this.showTab();
  }

}
