import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["togglee", "toggler"];

  // ACTIONS

  toggle(event) {
    let toggler = event.target;
    let togglee = this.toggleeTargets.find(
      (e) => e.dataset.toggleeId === toggler.dataset.togglerId
    );

    if (togglee.classList.contains("hidden")) {
      togglee.classList.remove("hidden");
      toggler.innerText = toggler.dataset.hideText;
    } else {
      togglee.classList.add("hidden");
      toggler.innerText = toggler.dataset.showText;
    }
  }
}
