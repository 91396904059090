import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['roleSelect', 'companySelectWrapper', 'companySelect'];

  // ACTIONS

  roleChange() {
    if (this.roleSelectTarget.value === 'user') {
      this.companySelectWrapperTarget.classList.remove('hidden');
    } else {
      this.companySelectWrapperTarget.classList.add('hidden');
      this.companySelectTarget.value = '';
    }
  }
}
