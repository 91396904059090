import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "menuItems",
    "closedIcon",
    "openedIcon"
  ];

  // ACTIONS

  toggle() {
    this.toggleMenuIcon();
    this.toggleMenuItems();
  }

  // PRIVATE

  toggleMenuIcon() {
    this.openedIconTarget.classList.toggle("hidden");
    this.closedIconTarget.classList.toggle("hidden");
  }

  toggleMenuItems() {
    this.menuItemsTarget.classList.toggle("hidden");
  }
}
