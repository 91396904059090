import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["uploadField", "fileField"];

  deleteFile(event) {
    event.preventDefault();

    this.fileFieldTarget.classList.add("hidden");
    this.uploadFieldTarget.classList.remove("hidden");
    this.uploadFieldTarget.disabled = false;
  }
}
